import { applyActionCode } from 'firebase/auth';
import React, { Component } from 'react';
import { auth } from '../../firebase';
import { Box, Container, Divider, Typography } from '@mui/material';



export default class VerifyEmail extends Component {
  state = {
    error: '',
    validCode: null,
    verifiedCode: false,
  }

  componentDidMount() {
    // Try to apply the email verification code.
    applyActionCode(auth,this.props.actionCode)
      .then(() => {
        // Email address has been verified.
        this.setState({ validCode: true, verifiedCode: true });
      }, error => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        this.setState({ error: error.message, validCode: false, verifiedCode: true });
      });
  }

  render() {
    const {
      error,
      validCode,
      verifiedCode,
    } = this.state;

    let component;
    if (!verifiedCode) {
      component = <div>Cargando...</div>;
    } else if (verifiedCode && validCode) {
      component = (
        <Container maxWidth="sm">
          <Box p={5}></Box>
          <Typography variant='h6' align='center'>Tu correo electrónico ha sido verificado. </Typography>
          <Divider />
          <Typography align='center'>Recuerda no compartir tus credenciales de acceso con nadie. Bienvenido a Polivoz!</Typography>
        </Container>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <Container maxWidth="sm">
          <Typography variant='h6' align='center'>Intente verificar su correo electrónico nuevamente</Typography>
          <Typography align='center'>{error}</Typography>
        </Container>
      );
    }

    return component;
  }
}