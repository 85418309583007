import React from 'react';
import ResetPassword from './ResetPassword';
import VerifyEmail from './VerifyEmail';
const Action = (props) => {
  const getParameterByName = (name) => {
    const url = new URL(window.location.href);
    return url.searchParams.get(name);
  };
  const mode = getParameterByName("mode");
  const actionCode = getParameterByName("oobCode");
  //const continueUrl = getParameterByName("continueUrl");
  //const lang = getParameterByName("lang") || "en";

  switch (mode) {
    case 'resetPassword':
      return <ResetPassword actionCode={actionCode} />;
    case 'verifyEmail':
      return <VerifyEmail actionCode={actionCode} />;
    default:
      // Error: invalid mode.
      return (
        <div className="Action">
          <h1>Error encountered</h1>
          <p>The selected page mode is invalid.</p>
        </div>
      );
  }
}
export default Action;