import { BrowserRouter, Route, Routes } from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Home from "./page/Home";
import PostsPage from "./page/posts/PostsPage";
import { loadStripe } from "@stripe/stripe-js";
import {
  Elements,
} from "@stripe/react-stripe-js";
import Action from "./page/accounts/Actions";

import VerifyEmail from "./page/accounts/VerifyEmail";




const stripePromise = loadStripe("pk_test_Jrx46GvieCTTAuc7rJ9NiKiC00jdBAPFfH");
const options = {
  mode: 'payment',
  amount: 1099,
  currency: 'usd',
  // Fully customizable with appearance API.
  appearance: {
    /*...*/
  },
};
function App() {
  return (
    <Elements stripe={stripePromise} options={options}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
       
          <Route path='/policy' element={<Home />} />
          <Route path='accounts'>
            <Route path="action" element={<Action />} />
            <Route path="verifyemail" element={<VerifyEmail />} />
          </Route>
          <Route path='/:username/posts/:postId' element={<PostsPage />} />
        </Routes>
      </BrowserRouter>
    </Elements>
  );
}
export default App;