import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import React, { Component } from 'react';
import { auth } from '../../firebase';
import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';


export default class ResetPassword extends Component {
  state = {
    email: null,
    error: '',
    password: '',
    success: false,
    validCode: null,
    verifiedCode: false,
  }

  componentDidMount() {
    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, this.props.actionCode)
      .then(email => {
        this.setState({ email, validCode: true, verifiedCode: true });
      }, error => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        this.setState({ error: error.message, validCode: false, verifiedCode: true });
      });
  }

  handleResetPassword = () => {

    const { actionCode } = this.props;
    const newPassword = this.state.password;
    // Save the new password.
    confirmPasswordReset(auth, actionCode, newPassword)
      .then(() => {
        // Password reset has been confirmed and new password updated.
        this.setState({ success: true });
      }, error => {
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
        console.log(error)
        this.setState({ error: error.message });
      });
  }

  setText = (evt) => {
    this.setState({ password: evt.target.value });
  }

  render() {
    const {
      email,
      error,
      password,
      success,
      validCode,
      verifiedCode,
    } = this.state;

    let component;
    if (!verifiedCode) {
      component = <div>Cargando...</div>;
    } else if (success) {
      component = (
        <Container maxWidth="xs">
          <Box p={5}></Box>
          <Typography>Contraseña cambiada</Typography>
          <Typography>Ya puedes iniciar sesión con tu nueva contraseña</Typography>
        </Container>
      );
    } else if (verifiedCode && validCode) {
      component = (
        <Container maxWidth="xs">
          <Box p={5}></Box>
          <Typography variant='h6'>Restablece tu contraseña</Typography>
          <Typography>de <span>{email}</span></Typography>
          <>

            {error ? <div className="error">{error}</div> : ''}
            <Stack spacing={2}>
              <TextField
                onChange={this.setText}
                value={password}
                type="password"
                size="small"
                placeholder="Nueva contraseña"

              />
              <Button
                disabled={!password}
                variant="contained"
                onClick={() => this.handleResetPassword()}
              >
                Guardar
              </Button>
            </Stack>

          </>
        </Container>
      );
    } else if (verifiedCode && !validCode) {
      component = (
        <div className="ResetPassword">
          <h1>Try resetting your password again</h1>
          <p className="error">{error}</p>
        </div>
      );
    }

    return component;
  }
}