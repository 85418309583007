import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyAWnNHzV4NGNJOy0G8-xvk2ucPFnbKl594",
  authDomain: "redquejas.firebaseapp.com",
  projectId: "redquejas",
  storageBucket: "redquejas.appspot.com",
  messagingSenderId: "234638513581",
  appId: "1:234638513581:web:e45fb8353c1215c0abfd24"
};
const app = initializeApp(firebaseConfig);
const db = getFirestore();
const auth = getAuth(app);
export { db, app, auth }